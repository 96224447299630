<template>
    <div class="card-tail" v-loading="$waiting.is('loading.details')">
        <div v-if="itemsData.account">
            <div>
                <p class="border-b pb-2 mb-2">{{ itemsData.account }} {{ itemsData.accountName }}</p>
            </div>
            <table class="w-full text-sm">
                <tr class="border-b ">
                    <th width="130" class="font-medium pb-1">Datum</th>
                    <th width="80" class="font-medium">Verifikat</th>
                    <th width="300" class="font-medium">Text</th>
                    <th width="20" />
                    <th width="110" align="right" class="font-medium">Debet</th>
                    <th width="110" align="right" class="font-medium">Kredit</th>
                    <th width="110" align="right" class="font-medium">Saldo</th>
                </tr>
                <tr v-for="trans in itemsData.transactions" :key="trans.id">
                    <td class="py-1">{{ trans.bookingDate }}</td>
                    <td class="py-1">{{ trans.number }}</td>
                    <td class="py-1">{{ trans.text }}</td>
                    <td class="py-1">{{ trans.correction ? "K" : "" }}</td>
                    <td class="py-1" align="right">{{ trans.debit | swedishNumberFormat }}</td>
                    <td class="py-1" align="right">{{ trans.credit | swedishNumberFormat }}</td>
                    <td class="py-1" align="right">{{ trans.balance | swedishNumberFormat }}</td>
                </tr>
            </table>
        </div>
        <div v-else class="flex flex-col items-center py-32">
            <img src="@/assets/images/no_data.svg" class="w-32" />
            <p class="mt-4">Ingen data har valts</p>
        </div>
    </div>
</template>
<script>
import Api from "../lopande.api";

export default {
    data() {
        return {
            itemsData: {},
        };
    },

    props: {
        accountNumber: {
            type: Number,
            default: null,
        },
        clientId: {
            type: String,
            default: "",
        },
        selectedDate: {
            type: Array,
            default: () => [],
        },
    },

    watch: {
        accountNumber() {
            if (!this.accountNumber) return;
            this.getDetails();
        },
    },

    methods: {
        async getDetails() {
            this.$waiting.start("loading.details");
            try {
                this.itemsData = await Api.getAccountDetails({ clientId: this.clientId, accountNumber: this.accountNumber, startDate: this.selectedDate[0], endDate: this.selectedDate[1] });
            } finally {
                this.$waiting.end("loading.details");
            }
        },

        resetState() {
            this.itemsData = {};
        },
    },
};
</script>
